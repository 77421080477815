<template>
  <div>
    <!-- 导航栏 -->
    <NavBar />

    <div class="intro">
      <div class="intro__header">隐私政策</div>
      <div class="intro__main">
        <div class="title">隐私政策声明</div>
        <div class="info">上海天存信息技术有限公司（下文简称“天存”、“我们”和“我们的”）深知隐私对您的重要性，并会尊重您的隐私。请在使用天存公司产品和服务之前，阅读和了解本《隐私政策》（下文简称“本政策”）。本政策阐述了天存如何处理您的个人数据，但本政策可能并不涉及所有可能的数据处理情境。有关产品或服务特定数据的信息可能由天存在补充政策中，或者在新产品/服务上线时提供的通知中发布。</div>
        <div class="content">
          <div class="content__item">
            <div class="content__title">天存如何收集和使用您的个人数据</div>
            <div class="content__info">天存公司提供的产品和服务包括独立商用安全防护产品系列和网站服务两部分：</div>
            <div class="content__sublist">
              <div class="content__subitem">在独立商用安全防护产品系列（包括 iGuard、iLocker和iWall/iWall WAF，以下简称“商用安全产品”），我们不会收集任何用户个人/机构相关数据。所有的数据只保存在商用安全产品对应安装的本地服务器/设备上，不涉及外部保存、使用和转移的问题。</div>
              <div class="content__subitem">天存公司提供的 iMon 网站监控服务涉及个人账号注册和个人信息保存。该类数据会在您使用我们的 iMon 服务时提供给我们。</div>
              <div class="content__subitem">在使用天存公司基于Web形式的服务（包括产品支持、手册和知识库查询、iMon 网站监控服务）时，我们可能会收集和使用非识别性数据。非识别性数据是指无法用于确定个人身份的数据。例如，天存会收集汇总的统计数据，例如网站访问量等，用于改善自身服务，更好地满足客户需求。天存可能会自行决定出于其他目的收集、使用、处理、转移或披露非识别性数据。</div>
            </div>
          </div>
          <div class="content__item">
            <div class="content__title">第三方服务提供商</div>
            <div class="content__info">我们不会和任何第三方服务器提供商分享您的个人/机构信息。</div>
          </div>
          <div class="content__item">
            <div class="content__title">天存如何保护和留存您的个人数据</div>
            <div class="content__info">天存重视个人数据的安全。我们采用适当的物理、管理和技术保障措施来保护您的个人数据不被未经授权访问、披露、使用、修改、损坏或丢失。例如，我们会使用加密技术确保数据的机密性；我们会使用保护机制防止数据遭到恶意攻击；我们会部署访问控制机制，确保只有授权人员才可访问个人数据；以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人数据重要性的认识。我们会尽力保护您的个人数据，但是请注意任何安全措施都无法做到无懈可击。</div>
          </div>
          <div class="content__item">
            <div class="content__title">数据记录</div>
            <div class="content__info">天存的商用安全产品数据仅记录和保存在您的本地机器上，包括所防护的资源、配置、所有日志、调试信息等。除非您主动提供和发送此类信息给我们，我们不会主动收集此类数据。天存的Web安全服务数据保存在公司选择的云平台服务器上。</div>
          </div>
          <div class="content__item">
            <div class="content__title">本政策如何更新</div>
            <div class="content__info">天存保留不时更新或修改本政策的权利。如果我们的隐私政策变更，我们会将最新版隐私政策发布在这里。如果我们对隐私政策做出了重大变更，我们还可能会通过不同渠道向您发送变更通知，例如，在我们的网站上发布通知或者向您发布单独的通知。</div>
          </div>
          <div class="content__item">
            <div class="content__title">如何联系我们</div>
            <div class="content__info">如果您有任何隐私投诉或问题请联系：privacy@tcxa.com.cn<br />最后更新时间：2019年8月14日</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 底部 -->
    <Footer />
  </div>
</template>
<script>
export default {

}
</script>
<style lang="scss" scoped>
.intro__header {
  display: flex;
  align-items: center;
  width: 100%;
  height: 126px;
  left: 322px;
  font-size: 40px;
  font-weight: 700;
  font-family: Source Han Sans CN;
  padding-left: 323px;
  color: rgba(112, 112, 112, 1);
  background: rgba(242, 242, 242, 1);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);

  @media screen and (max-width: 750px) {
    font-size: 72px;
    padding-left: 90px;
    height: 178px;
  }
}
.intro__main {
  margin: 100px 322px;
  font-size: 20px;
  font-weight: 400;
  font-family: Source Han Sans CN;
  font-family: Source Han Sans CN;
  color: rgba(0, 0, 0, 1);
  box-shadow: 3px 6px 9px 0px rgba(0, 0, 0, 0.22);
  border-radius: 12px;
  background: rgba(255, 255, 255, 1);
  overflow: hidden;
  padding: 50px 50px 100px 50px;

  @media screen and (max-width: 750px) {
    margin: 0;

    .title {
      font-size: 54px !important;
      padding-top: 80px;
      padding-bottom: 40px !important;
    }
  }

  .title {
    font-size: 28px;
    font-weight: 600;
    font-family: Source Han Sans CN;
    text-align: center;
    letter-spacing: 1px;
    color: rgba(64, 64, 64, 1);
    padding-bottom: 32px;
  }

  .info {
    font-size: 16px;
    font-weight: 400;
    font-family: Source Han Sans CN;
    line-height: 1.8;
    letter-spacing: 1px;
    padding-bottom: 26px;
    text-align: justify;
    color: rgba(112, 112, 112, 1);
    border-bottom: 2px solid rgba(204, 204, 204, 1);

    @media screen and (max-width: 750px) {
      font-size: 36px;
    }
  }

  .content {
    @media screen and (max-width: 750px) {
      .content__title {
        font-size: 48px !important;
      }
      .content__info {
        font-size: 36px !important;
        padding-top: 40px !important;
      }
      .content__sublist {
        padding-left: 55px !important;
      }
      .content__subitem {
        font-size: 36px !important;
        padding-left: 35px !important;
        &::before {
          width: 25px !important;
          height: 25px !important;
          top: 20px !important;
        }
      }
    }

    .content__item {
      margin-top: 30px;
    }

    .content__title {
      font-size: 24px;
      font-weight: 600;
      font-family: Source Han Sans CN;
      text-align: justify;
      letter-spacing: 1px;
      line-height: 1.8;
      color: rgba(253, 147, 30, 1);
    }
    .content__info {
      font-size: 16px;
      font-weight: 400;
      font-family: Source Han Sans CN;
      line-height: 1.8;
      letter-spacing: 1px;
      text-align: justify;
      padding-top: 10px;
      color: rgba(93, 93, 93, 1);
    }
    .content__sublist {
      padding-left: 45px;
    }
    .content__subitem {
      position: relative;
      font-size: 16px;
      font-weight: 400;
      font-family: Source Han Sans CN;
      text-align: justify;
      line-height: 1.5;
      padding-left: 26px;
      color: rgba(93, 93, 93, 1);
      padding-top: 10px;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 16px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: rgba(253, 147, 30, 1);
      }

      @media screen and (max-width: 750px) {
        font-size: 42px;
      }
    }
  }

  p + p {
    padding-top: 40px;
  }
}
</style>
